<template>
  <div class="mgd-login">
    <el-form
      ref="SmsForm"
      :model="SmsForm"
      :rules="smsRules"
      size="large"
      auto-complete="on"
      label-position="left"
      class="mg_form"
      @keyup.enter.native="handleLogin"
    >
      <el-form-item prop="phone" class="input-box">
        <el-input
          v-model="SmsForm.phone"
          placeholder="请输入手机号"
          type="number"
          auto-complete="on"
          :class="inpcss == 0 ? 'inpfocus' : 'inpblur'"
          clearable
          :maxlength="11"
        >
          <template slot="prepend">+86</template>
        </el-input>
      </el-form-item>

      <el-form-item prop="msgCaptcha" class="input-box">
        <el-input
          v-model="SmsForm.msgCaptcha"
          type="text"
          placeholder="请输入验证码"
          auto-complete="on"
          :class="inpcss == 1 ? 'inpfocus' : 'inpblur'"
          :maxlength="6"
          clearable
          @keyup.enter.native="handleLogin"
        />
        <div class="butyzm" @click="getSmsCodeFn">
          {{ content }}
        </div>
      </el-form-item>
      <el-form-item type="flex" class="row-bg" justify="space-between" style="margin: 0 0 0.75rem;">
        <el-checkbox v-model="agreement" class="agreement">
          <span class="txt">已阅读并同意</span>
          <a target="_blank" class="tips" :href="serviceProtocol"
            >《用户服务协议》</a
          ><span class="txt"> 和 </span
          ><a target="_blank" class="tips" :href="propertyProtocol"
            >《隐私政策》</a
          >
        </el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button
          class="login-btn"
          :loading="loading"
          type="primary"
          @click.native.prevent="handleLoginSms"
          >立即注册 免费发布职位
        </el-button>
      </el-form-item>
    </el-form>

    <el-button
      class="login-btn2"
      :loading="loading"
      type="primary"
      @click.native.prevent="goLoginView"
      >已有企业账号，直接登录
    </el-button>
  </div>
</template>

<script>
import utils from "@/utils";
import {
  getJumpUrl,
  encryptCode,
  getUserSmsCode,
  loginUserSms,
} from "@/api/login";

export default {
  name: "mgd-login-v2",
  data() {
    const validateRegPhone = (rule, value, callback) => {
      if (!utils.isPoneAvailable(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      serviceProtocol: utils.Protocol.ServiceAgreement,
      propertyProtocol: utils.Protocol.PrivacyAgreement,
      dynamicValidateForm: {
        phone: "",
        code: "",
      },
      inpcss: -1,
      loading: false,
      agreement: false,
      content: "获取验证码",
      totalTime: 60,
      getVerificationCode: false,

      smsRules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            required: true,
            trigger: ["blur", "change"],
            validator: validateRegPhone,
          },
        ],
        msgCaptcha: [
          {
            required: true,
            trigger: "blur",
            message: "请输入验证码",
          },
        ],
      },
      SmsForm: {
        phone: "",
        msgCaptcha: "",
      },
    };
  },
  mounted() {},

  methods: {
    getSmsCodeFn() {
      if (this.getVerificationCode) {
        return;
      }
      if (!utils.isPoneAvailable(this.SmsForm.phone)) {
        this.$message.error("请输入正确手机号");
        return;
      }
      const param = {
        phone: this.SmsForm.phone,
        type: 1,
        loginType: "web",
      };
      param.type = 6;
      this.content = "加载中...";
      getUserSmsCode(param)
        .then((data) => {
          if (data.msg === "成功") {
            this.$message({
              message: "验证码已发送！",
              type: "success",
            });

            this.content = this.totalTime + "秒后重新发送";
            const clock = window.setInterval(() => {
              this.totalTime--;
              this.content = this.totalTime + "秒后重新发送";
              if (this.totalTime < 0) {
                window.clearInterval(clock);
                this.content = "重新获取";
                this.totalTime = 60;
                this.getVerificationCode = false;
              }
            }, 1000);
          } else {
            this.getVerificationCode = false;
            this.content = "获取验证码";
            this.$message({
              message: data.msg,
              type: "info",
            });
          }
        })
        .catch(() => {
          this.content = "重新获取";
          this.getVerificationCode = false;
        });
      this.judgereg = false;
      this.getVerificationCode = true;
    },
    handleLoginSms() {
      // if (!utils.isPoneAvailable(this.SmsForm.phone)) {
      //   this.$message.error("请输入正确手机号");
      //   return;
      // }
      // if (this.SmsForm.msgCaptcha === "") {
      //   this.$message.error("请输入短信验证码");
      //   return;
      // }
      if (!this.agreement) {
        this.$message({
          message: "请阅读并接受蘑菇丁《服务协议》与《隐私政策》",
          type: "error",
          duration: 1500,
        });
        return;
      }
      this.$refs.SmsForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const params = {
            phone: this.SmsForm.phone,
            smsCode: this.SmsForm.msgCaptcha,
            loginType: "web",
            // judgereg: this.judgereg, // true-注册，false-登录
          };
          loginUserSms(params)
            .then((resp) => {
              this.loading = false;

              if (resp.code === 200) {
                this.onJumpManageBackground(
                  resp.data.token,
                  resp.data.userType,
                  resp.data
                );
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    // 登录到后台
    onJumpManageBackground(token, userType, query) {
      let url = getJumpUrl();
      const id = encryptCode(query);

      // token存在登录成功
      if (token) {
        // profile 待认证用户, enterprise 企业用户
        if (userType === "enterprise") {
          url += "/login?source=mgd_index" + "&id=" + id;
        } else {
          url += "/invitationRegister?source=mgd_index" + "&id=" + id;
        }
      } else {
        // token为null 表示用户不存在, 跳转至绑定手机号页面
        url += "/bindingAccount?source=mgd_index" + "&id=" + id;
      }

      location.href = url;
      // window.open(url)
    },
    goLoginView() {
      let url = getJumpUrl();
      location.href = url + "/login";
    },
  },
};
</script>

<style lang="less" scoped>
.mgd-login {
  @primary-color: #fe8a12;
  width: 100%;
  font-size: 0.875rem;
  /deep/ .mg_form {
    .el-form-item {
      margin-bottom: 1.8rem;
    }
    .el-input {
      border-radius: 24px;
      font-size: 0.875rem;
      .el-input-group__prepend,
      .el-input__inner,
      .el-input-group__append {
        background: #f6f6f8;
        border-color: transparent;
        padding: 0 1.25rem;
      }
      .el-input__inner {
        height: 3.125rem;
        line-height: 3.125rem;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    .el-checkbox__inner {
      width: 0.875rem;
      height: 0.875rem;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #fe8a12;
      border-color: #fe8a12;
      font-size: 0.875rem;
    }
    .el-form-item__content {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 2.5rem;
      font-size: 0.875rem;
      .butyzm {
        cursor: pointer;
        white-space: nowrap;
        padding: 0 1.25rem;
        margin-left: 0.9375rem;
        color: @primary-color;
        background-color: #fff4e7;
        border-radius: 4px;
        font-size: 0.875rem;
        line-height: 3.125rem;
      }
    }
    .input-box {
      .el-input-group__prepend,
      .el-input-group__append {
        font-weight: bold;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 1rem;
          right: 0;
          background: #ebebeb;
          width: 1px;
          height: 1.25rem;
          line-height: 1.25rem;
        }
      }
      .el-input-group__append::after {
        left: 0;
      }
    }

    .row-bg {
      // margin: 3.125rem 0 1rem;
      .el-checkbox {
        font-size: 0.875rem;
      }
      .agreement {
        display: flex;
        align-items: start;
        width: 100%;
        font-weight: 100;
        .txt {
          color: #999999;
        }

        .tips {
          color: @primary-color;
          cursor: pointer;
          text-decoration: none;
        }

        :nth-child(2) {
          line-height: 0.875rem;
          font-size: 0.875rem;
          text-wrap: wrap;
        }
      }
    }
    .login-btn {
      width: 100%;
      border-color: @primary-color;
      background-color: @primary-color;
    }
    .el-form-item__error {
      font-size: 0.875rem;
    }
    .el-button {
      font-size: 0.875rem;
      padding: 0.9rem 1.25rem;
    }
  }
  .login-btn2 {
    color: @primary-color;
    border-color: @primary-color;
    background-color: transparent;
    width: 100%;
    font-size: 0.875rem;
    padding: 0.9rem 1.25rem;
    border: 0.0625rem solid;
  }
}
</style>
