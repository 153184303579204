// 协议
const Protocol = {
  ServiceAgreement: "https://www.moguding.net/agreenment.html ", // 用户服务协议
  PrivacyAgreement: "https://www.moguding.net/privacyPolicy.html" // 隐私协议
};

/**
 * 验证手机号
 */
function isPoneAvailable(poneInput) {
    const myreg = /^[1][0-9][0-9]{9}$/;
    if (!myreg.test(poneInput)) {
        return false;
    } else {
        return true;
    }
}

export default { Protocol, isPoneAvailable };
