import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: '首页' },
    children: [{ path: '/home', component: HomeView, meta: { title: '首页' } }]
  },
  {
    path: '/home-v1',
    meta: { title: '首页' },
    component: () => import('../views/HomeViewV1'),
  },
  {
    path: '/school',
    component: () => import('../views/SchoolView'),
    meta: { title: '合作学校' }
  },
  {
    path: '/nationwide',
    component: () => import('../views/Nationwide'),
    meta: { title: '全国招聘会' }
  },
  {
    path: '/about',
    component: () => import('../views/AboutView'),
    meta: { title: '关于我们' }
  }
  ,
  {
    path: '/recruit',
    component: () => import('../views/RecruitView'),
    meta: { title: '线上招聘' }
  }
  ,
  {
    path: '/blackriverView',
    component: () => import('../views/blackriverView'),
    meta: { title: '黑龙江招聘会' }
  },
  // {
  //   path: '/news/:page',
  //   component: () => import('../views/NewsView'),
  //   meta: { title: '新闻' }
  // }
  {
    path: '/register',
    component: () => import('../views/RegisterView'),
    meta: { title: '注册' }
  },
  {
    path: '/login',
    component: () => import('../views/LoginView'),
    meta: { title: '登录' }
  },
  {
    path: '/goMiniProgram',
    component: () => import('../views/GoMiniProgramView'),
    meta: { title: '官网' }
  },
  {
    path: '/downloadApp',
    component: () => import('../views/DownloadAppView'),
    meta: { title: '蘑菇钉App下载' }
  },
  /* {
    path: '/legal',
    component: () => import('../views/LegalView'),
    meta: { title: '隐私政策' },
    children: [
      {
        path: '/sdk',
        component: () => import('../views/components/SDKListView.vue'),
        meta: { title: '第三方SDK列表' }
      },
      {
        path: '/info',
        component: () => import('../views/components/InfoView.vue'),
        meta: { title: '个人信息手机清单' }
      },
      {
        path: '/privacy',
        component: () => import('../views/components/PrivacyView.vue'),
        meta: { title: '隐私政策' }
      },
      {
        path: '/customer',
        component: () => import('../views/components/CustomerView.vue'),
        meta: { title: '用户服务协议' }
      },
      {
        path: '/increment',
        component: () => import('../views/components/IncrementView.vue'),
        meta: { title: '在线增值服务协议' }
      },
      {
        path: '/position',
        component: () => import('../views/components/PositionView.vue'),
        meta: { title: '职位发布规则' }
      },
      {
        path: '/qualifications',
        component: () => import('../views/components/QualificationsView.vue'),
        meta: { title: '资质公示' }
      }
    ]
  } */
]

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `蘑菇丁官网-${to.meta.title}`
  return next()
})

export default router
